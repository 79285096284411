import { ReactNode, useState } from "react";

import analytics from "@lib/analytics";

interface UnfoldingDetailsProps {
  type?: string;
  children: ReactNode;
  heapName?: string;
}

const UnfoldingDetails: React.FC<UnfoldingDetailsProps> = ({
  type = "pricing",
  children,
  heapName,
}) => {
  const [showDetails, setShowDetails] = useState(false);

  const handleClick = () => {
    if (!showDetails && heapName) analytics.track(heapName);
    setShowDetails(!showDetails);
  };

  return (
    <div>
      {showDetails && (
        <div className="w-full flex justify-center">{children}</div>
      )}
      <div
        className="py-3 text-foreground/50 -mx-4 border-t border-foreground/25 text-center cursor-pointer text-sm"
        onClick={handleClick}
      >
        {showDetails ? "Hide" : "Show"} {type} details
      </div>
    </div>
  );
};

export default UnfoldingDetails;
