import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const Icon: FC<SVGIconProps> = ({ className }) => (
  <svg
    className={classNames("fill-current", className)}
    width="150"
    height="34"
    viewBox="0 0 150 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_263_16219)">
      <path d="M146 0H3.73C2.74074 0 1.792 0.392981 1.09249 1.09249C0.392981 1.792 0 2.74074 0 3.73L0 30.27C0 31.2593 0.392981 32.208 1.09249 32.9075C1.792 33.607 2.74074 34 3.73 34H146C147.061 34 148.078 33.5786 148.828 32.8284C149.579 32.0783 150 31.0609 150 30V4C150 2.93913 149.579 1.92172 148.828 1.17157C148.078 0.421427 147.061 0 146 0V0ZM149 30C149 30.7956 148.684 31.5587 148.121 32.1213C147.559 32.6839 146.796 33 146 33H3.73C3.00677 32.9974 2.31391 32.7089 1.80251 32.1975C1.2911 31.6861 1.00263 30.9932 1 30.27V3.73C1.00263 3.00677 1.2911 2.31391 1.80251 1.80251C2.31391 1.2911 3.00677 1.00263 3.73 1H146C146.796 1 147.559 1.31607 148.121 1.87868C148.684 2.44129 149 3.20435 149 4V30Z" />
      <path d="M17.07 11.2402H12.77V22.0002H14.69V17.8402H17.07C19.47 17.8402 20.97 16.6802 20.97 14.5402C20.97 12.4002 19.47 11.2402 17.07 11.2402ZM16.97 16.2402H14.69V12.9402H17C18.38 12.9402 19.11 13.5302 19.11 14.5902C19.11 15.6502 18.35 16.1902 17 16.1902L16.97 16.2402Z" />
      <path d="M25.1 13.9998C24.5743 13.9937 24.0531 14.0976 23.5699 14.3049C23.0868 14.5122 22.6523 14.8183 22.2945 15.2034C21.9366 15.5886 21.6633 16.0444 21.492 16.5414C21.3207 17.0385 21.2553 17.5659 21.3 18.0898C21.2531 18.6165 21.3164 19.1472 21.4858 19.6482C21.6552 20.1491 21.9271 20.6093 22.2841 20.9994C22.641 21.3896 23.0753 21.7011 23.5592 21.9143C24.0432 22.1274 24.5662 22.2375 25.095 22.2375C25.6238 22.2375 26.1468 22.1274 26.6308 21.9143C27.1147 21.7011 27.549 21.3896 27.906 20.9994C28.2629 20.6093 28.5348 20.1491 28.7042 19.6482C28.8736 19.1472 28.9369 18.6165 28.89 18.0898C28.9362 17.5664 28.8721 17.0392 28.702 16.5422C28.5319 16.0451 28.2596 15.5892 27.9025 15.2039C27.5454 14.8185 27.1115 14.5123 26.6288 14.3049C26.1461 14.0975 25.6253 13.9936 25.1 13.9998ZM25.1 20.6698C23.88 20.6698 23.1 19.6698 23.1 18.0898C23.1 16.5098 23.86 15.5098 25.1 15.5098C26.34 15.5098 27.1 16.5098 27.1 18.0898C27.1 19.6698 26.31 20.6598 25.1 20.6598V20.6698Z" />
      <path d="M36.78 19.3499L35.37 14.1299H33.89L32.49 19.3499L31.07 14.1299H29.22L31.59 22.0099H33.15L34.59 16.8499L36.03 22.0099H37.59L39.96 14.1299H38.18L36.78 19.3499Z" />
      <path d="M44 14C43.4811 14.0096 42.9697 14.1246 42.4966 14.3379C42.0236 14.5513 41.5988 14.8585 41.2481 15.241C40.8974 15.6235 40.6281 16.0733 40.4565 16.563C40.2849 17.0528 40.2147 17.5723 40.25 18.09C40.2082 18.6157 40.2766 19.1444 40.4508 19.6422C40.625 20.14 40.9012 20.596 41.2616 20.9809C41.6221 21.3659 42.059 21.6714 42.5443 21.8778C43.0296 22.0843 43.5526 22.1872 44.08 22.18C44.805 22.2191 45.524 22.0297 46.1357 21.6386C46.7475 21.2476 47.2211 20.6744 47.49 20L46 19.38C45.8831 19.7664 45.6382 20.1016 45.3057 20.3306C44.9732 20.5595 44.5727 20.6686 44.17 20.64C43.8831 20.6468 43.5979 20.5954 43.3315 20.4888C43.0652 20.3821 42.8232 20.2226 42.6203 20.0197C42.4174 19.8168 42.2578 19.5748 42.1512 19.3085C42.0446 19.0421 41.9931 18.7568 42 18.47H47.52V17.87C47.54 15.71 46.32 14 44 14ZM42.07 17.13C42.1401 16.6708 42.3743 16.2526 42.7292 15.9529C43.084 15.6532 43.5355 15.4923 44 15.5C44.2207 15.4813 44.4428 15.5099 44.6516 15.5837C44.8603 15.6576 45.051 15.775 45.2109 15.9283C45.3708 16.0815 45.4962 16.2671 45.5788 16.4726C45.6614 16.678 45.6993 16.8987 45.69 17.12L42.07 17.13Z" />
      <path d="M50.69 15.3V14.13H48.89V22H50.69V17.87C50.674 17.6031 50.7147 17.3359 50.8096 17.086C50.9044 16.8361 51.0512 16.6091 51.2402 16.4201C51.4292 16.2311 51.6562 16.0843 51.9061 15.9895C52.156 15.8947 52.4232 15.8539 52.69 15.87C52.9097 15.8544 53.1303 15.8544 53.35 15.87V14.07C53.21 14.07 53.05 14.07 52.84 14.07C52.4014 14.0472 51.9656 14.1511 51.5844 14.3692C51.2032 14.5873 50.8927 14.9104 50.69 15.3Z" />
      <path d="M57.48 14C56.9612 14.0096 56.4497 14.1246 55.9766 14.3379C55.5036 14.5513 55.0788 14.8585 54.7281 15.241C54.3774 15.6235 54.1081 16.0733 53.9365 16.563C53.7649 17.0528 53.6947 17.5723 53.73 18.09C53.6882 18.6157 53.7566 19.1444 53.9308 19.6422C54.105 20.14 54.3812 20.596 54.7417 20.9809C55.1021 21.3659 55.539 21.6714 56.0243 21.8778C56.5096 22.0843 57.0327 22.1872 57.56 22.18C58.2784 22.2106 58.9885 22.0172 59.5922 21.6267C60.1959 21.2361 60.6634 20.6677 60.93 20L59.39 19.41C59.2731 19.7964 59.0283 20.1316 58.6957 20.3606C58.3632 20.5895 57.9627 20.6986 57.56 20.67C57.279 20.6674 57.0014 20.609 56.7432 20.4982C56.485 20.3874 56.2514 20.2263 56.056 20.0244C55.8606 19.8225 55.7073 19.5837 55.6049 19.322C55.5026 19.0603 55.4534 18.7809 55.46 18.5H61V17.9C61 15.71 59.76 14 57.48 14ZM55.55 17.13C55.622 16.6744 55.8557 16.2599 56.2083 15.9624C56.5609 15.6649 57.0087 15.5043 57.47 15.51C57.6907 15.4913 57.9128 15.5199 58.1216 15.5937C58.3304 15.6676 58.521 15.785 58.6809 15.9383C58.8408 16.0915 58.9662 16.2771 59.0488 16.4826C59.1314 16.688 59.1693 16.9087 59.16 17.13H55.55Z" />
      <path d="M67.56 15.0002C67.2825 14.6746 66.9353 14.4156 66.544 14.2425C66.1528 14.0694 65.7276 13.9866 65.3 14.0002C63.09 14.0002 61.83 15.8502 61.83 18.0902C61.83 20.3302 63.09 22.1802 65.3 22.1802C65.7279 22.1961 66.1538 22.1143 66.5454 21.941C66.937 21.7677 67.284 21.5076 67.56 21.1802V22.0002H69.36V11.2402H67.56V15.0002ZM67.56 18.3502C67.6003 18.6354 67.5786 18.9258 67.4963 19.2018C67.4141 19.4778 67.2731 19.7327 67.0832 19.9492C66.8933 20.1657 66.6589 20.3386 66.396 20.4562C66.1331 20.5737 65.848 20.6331 65.56 20.6302C64.25 20.6302 63.56 19.6302 63.56 18.1102C63.56 16.5902 64.26 15.5902 65.56 15.5902C66.67 15.5902 67.56 16.4002 67.56 17.8802V18.3502Z" />
      <path d="M79.31 14.0002C78.8744 13.9812 78.4401 14.0613 78.04 14.2345C77.6398 14.4077 77.2842 14.6696 77 15.0002V11.2402H75.2V22.0002H77V21.1702C77.279 21.4966 77.628 21.756 78.021 21.9291C78.4139 22.1023 78.8408 22.1847 79.27 22.1702C81.47 22.1702 82.73 20.3102 82.73 18.0802C82.73 15.8502 81.51 14.0002 79.31 14.0002ZM79 20.6002C78.7121 20.6031 78.4269 20.5437 78.164 20.4262C77.9011 20.3086 77.6667 20.1357 77.4768 19.9192C77.2869 19.7027 77.146 19.4478 77.0637 19.1718C76.9814 18.8958 76.9597 18.6054 77 18.3202V17.8502C77 16.3702 77.84 15.5602 79 15.5602C80.3 15.5602 81 16.5602 81 18.0802C81 19.6002 80.25 20.6002 79 20.6002Z" />
      <path d="M86.93 19.6599L85 14.1299H83.1L86 21.7199L85.7 22.4599C85.649 22.7134 85.5018 22.9373 85.2892 23.0846C85.0766 23.2319 84.8153 23.2912 84.56 23.2499C84.3602 23.2645 84.1597 23.2645 83.96 23.2499V24.7599C84.2016 24.7958 84.4457 24.8125 84.69 24.8099C85.3143 24.8553 85.9347 24.6799 86.4429 24.3143C86.951 23.9487 87.3145 23.4163 87.47 22.8099L90.71 14.1899H88.82L86.93 19.6599Z" />
      <path d="M125 12.4302C124.605 12.4279 124.214 12.5036 123.848 12.6529C123.483 12.8022 123.15 13.0222 122.87 13.3002L122.73 12.6102H120.34V25.5302L123.06 24.9402V21.8102C123.601 22.2633 124.284 22.5111 124.99 22.5102C126.93 22.5102 128.71 20.9202 128.71 17.4002C128.71 14.1802 126.91 12.4302 125 12.4302ZM124.35 20.0602C124.111 20.074 123.873 20.0344 123.651 19.9445C123.43 19.8546 123.231 19.7165 123.07 19.5402V15.4302C123.232 15.2475 123.433 15.1034 123.658 15.0082C123.883 14.9131 124.126 14.8693 124.37 14.8802C125.37 14.8802 126.05 16.0102 126.05 17.4602C126.05 18.9102 125.36 20.0602 124.35 20.0602Z" />
      <path d="M133.73 12.4302C131.11 12.4302 129.52 14.6902 129.52 17.5402C129.52 20.9102 131.4 22.6202 134.08 22.6202C135.126 22.6372 136.159 22.3859 137.08 21.8902V19.6402C136.243 20.0623 135.317 20.275 134.38 20.2602C133.3 20.2602 132.38 19.8702 132.24 18.5602H137.62C137.62 18.4102 137.62 17.8202 137.62 17.5602C137.71 14.6902 136.35 12.4302 133.73 12.4302ZM132.26 16.5002C132.26 15.2402 133.03 14.7102 133.71 14.7102C134.39 14.7102 135.11 15.2402 135.11 16.5002H132.26Z" />
      <path d="M113 13.36L112.83 12.54H110.51V22.25H113.19V15.67C113.432 15.3762 113.758 15.1632 114.124 15.0595C114.491 14.9559 114.88 14.9665 115.24 15.09V12.54C114.831 12.3798 114.377 12.3747 113.965 12.5257C113.552 12.6768 113.209 12.9735 113 13.36Z" />
      <path d="M99.46 15.4601C99.46 15.0201 99.82 14.8501 100.39 14.8501C101.335 14.8704 102.261 15.1174 103.09 15.5701V12.9401C102.233 12.5934 101.315 12.4201 100.39 12.4301C98.18 12.4301 96.71 13.6101 96.71 15.5901C96.71 18.6901 100.85 18.1901 100.85 19.5201C100.85 20.0401 100.41 20.2101 99.85 20.2101C98.7935 20.1484 97.7661 19.8401 96.85 19.3101V22.0001C97.7942 22.4212 98.8162 22.6392 99.85 22.6401C102.11 22.6401 103.67 21.4901 103.67 19.4801C103.62 16.1201 99.46 16.7201 99.46 15.4601Z" />
      <path d="M107.28 10.2402L104.63 10.8202V19.7502C104.616 20.1266 104.68 20.5017 104.816 20.8528C104.952 21.2039 105.158 21.5235 105.422 21.7921C105.686 22.0607 106.002 22.2727 106.351 22.4151C106.699 22.5575 107.073 22.6273 107.45 22.6202C108.107 22.6496 108.762 22.5228 109.36 22.2502V20.0002C109.01 20.1502 107.3 20.6602 107.3 19.0002V15.0002H109.36V12.6602H107.3L107.28 10.2402Z" />
      <path d="M116.25 11.7002L118.98 11.1302V8.97021L116.25 9.54021V11.7002Z" />
      <path d="M118.98 12.6099H116.25V22.2499H118.98V12.6099Z" />
    </g>
    <defs>
      <clipPath id="clip0_263_16219">
        <rect width="150" height="34" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Icon;
