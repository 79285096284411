import PoweredByStripe from "@components/Icons/PoweredByStripe";
import ShieldIcon from "@components/Icons/ShieldIcon";
import { Tooltip, TooltipSize } from "@components/Tooltips/Tooltip";

const SafePaymentTooltip: React.FC = () => (
  <Tooltip
    trigger={<ShieldIcon className="text-green-600" />}
    size={TooltipSize.large}
  >
    <div>
      <h2 className="font-semibold text-base">Your payments are safe</h2>
      <p className="text-grey-500 py-2 text-sm">
        Your payments are guaranteed safe and secure by our trusted service
        provider Stripe.
      </p>
      <PoweredByStripe />
    </div>
  </Tooltip>
);
export default SafePaymentTooltip;
